"use strict";

import $ from 'jquery';
import slick from 'slick-carousel';
import 'slick-carousel/slick/slick.scss';

// dash.js
import "./common/common.js";

// video.js
import videojs from 'video.js';
import './common/css/video-js.css';
// jquery.modal.js
import './common/modal.js';
import './common/css/modal.css';


let fnc;

const breakpoint = 768;

class Fnc {
  constructor() {
    this.device = (navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/i)) ? 'sp' : 'pc';
    this.window = {
      w: $(window).innerWidth(),
      h: $(window).innerHeight(),
    };
    this.eventFlag = {
      sliderL: false,
      scroll: false,
    };
    this.count = {
      scroll: 0,
    }
  }

  ready() {
    this.margins();
    this.anchor();
    // this.slick();
    this.video();
    this.commentEvent();
    this.downloadEvent();
  }

  loaded() {
    $('body').addClass('loaded');

    if (fnc.window.w <= breakpoint) {
      fnc.scrollbar();
    }

    setTimeout(() => {
      $('.load_bg').remove();
    }, 300);
  }

  resize() {
    this.window.w = $(window).innerWidth();
    this.window.h = $(window).innerHeight();

    this.device = (navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/i)) ? 'sp' : 'pc';

    this.margins();
    this.video();

    if (fnc.window.w <= breakpoint) {
      fnc.scrollbar();
    }
  }

  margins() {
    const header = $('header');
    const target = $('main');
    const h = header.innerHeight();
    target.css('margin-top', h);
  }

  anchor() {
    const t = this;
    // #で始まるアンカーをクリックした場合に処理
    $('a[href^="#"]').not('a[rel^="modal"]').on('click', function() {
      // スクロールの速度
      var speed = 400; // ミリ秒
      // アンカーの値取得
      var href = $(this).attr("href");
      // 移動先を取得
      var targetHref = href == "#" || href == "" ? 'html' : href;
      var target = $(targetHref);
      // 移動先を数値で取得
      let position = target.offset().top;
      position -= $('header').innerHeight();


      // スムーススクロール
      $('body,html').animate({
        scrollTop: position
      }, speed, 'swing');
      return false;
    });
  }

  slick() {
    const target = $('.js-slider-l');

    target.slick({
      arrows: false,
      dots: true,
      dotsClass: 'kv__dots',
    });
  }

  scrollbar() {
    const target = $('.js-scroll');
    // onevent引数
    let start = (fnc.device == 'pc') ? 'mousedown' : 'touchstart';
    let end = (fnc.device == 'pc') ? 'mouseup' : 'touchend';
    let move = (fnc.device == 'pc') ? 'mousemove' : 'touchmove';

    $(document).off(end + " selectstart " + move);

    $('.js-scroll__box').off('scroll');
    $('.js-scroll__scrollbar__thumb').off(start);

    target.each(function() {
      // 変数(要素)
      const t = $(this);
      const box = t.find('.js-scroll__box');
      const contents = t.find('.js-scroll__contents');
      const scrollbar = t.find('.js-scroll__scrollbar');
      const thumb = t.find('.js-scroll__scrollbar__thumb');

      // 変数(幅)
      let box_width = box.width();
      let contents_width = contents.outerWidth();
      let scrollbar_width = scrollbar.outerWidth();
      let ratio = scrollbar_width / box_width;
      let thumb_width = parseInt(box_width * box_width / contents_width * ratio);
      let scrollbar_track = scrollbar_width - thumb_width;

      // 変数(つまみ計算)
      let active = false;
      let scrollbar_thumb_cursor_x;
      t.addClass('non-scroll');

      if (contents_width <= box_width) {
        scrollbar.css({
          'visibility': 'hidden',
          'opacity': '0',
        });
        thumb.css('width', 0);
      } else {
        scrollbar.css({
          'visibility': 'visible',
          'opacity': '1',
        });
        thumb.css('width', thumb_width);
      }

      box.on('scroll', function() {
        if (!fnc.eventFlag.scroll) t.removeClass('non-scroll');
        var offset = $(this).scrollLeft() * scrollbar_track / (contents_width - box_width) * ratio;
        thumb.css('transform', 'translateX(' + offset + 'px)');
      });

      thumb.on(start, function(event) {
        active = true;
        if (fnc.device == 'sp') event = event.changedTouches[0];
        scrollbar_thumb_cursor_x = event.pageX - $(this).offset().left;
      });

      $(document).on(end, function() {
        active = false;
      });

      $(document).on(move, function(event) {
        if (!active) return;
        if (fnc.device == 'sp') event = event.changedTouches[0];
        var scrollbar_thumb_x = ((event.pageX - scrollbar.offset().left) / scrollbar_track * scrollbar_track) - scrollbar_thumb_cursor_x;

        // つまみが上下の領域外を超えないようにする
        if (scrollbar_thumb_x < 0) {
          scrollbar_thumb_x = 0;
        } else if (scrollbar_thumb_x > scrollbar_track) {
          scrollbar_thumb_x = scrollbar_track;
        }

        // つまみの位置設定
        thumb.css('transform', 'translateX(' + scrollbar_thumb_x + 'px)');

        // つまみの位置に応じてスクロールさせる
        box.scrollLeft((thumb.offset().left - scrollbar.offset().left) / scrollbar_track * (contents_width - box_width) / ratio);
      });

      // つまみを操作中はテキスト選択できないようにする
      $(document).on('selectstart', function() {
        if (active) return false;
      });
    });
  }

  video() {
    const target = (this.device === 'pc') ? $("#video") : $("#videoSp");
    const video = (this.device === 'pc') ? document.querySelector("#video") : document.querySelector("#videoSp");
    let url;
    let player = (this.device === 'pc') ? dashjs.MediaPlayer().create() : videojs('videoSp');

    if (this.device === 'pc') {
      url = target.attr('data-pc');
      $('#videoSp').css('display', "none");
      target.show();
    }
    if (this.device === 'sp') {
      url = target.attr('data-sp');
      $('#video').css('display', "none");
      target.show();
    }

    target.on('click', function() {
      if (fnc.device === 'pc') {
        target.addClass('loaded');
        target.attr('controls', 'controls');
        player.initialize(video, url, true);
        target.off('click');
      } else {
        player.play();
      }
    });
  }

  commentEvent() {
    const target = $('.download__comment__list .download__comment__item-anchor');
    const target2 = $('.download__images__item');
    const target3 = $('.download__item-anchor');

    target.parent().not('.display').hide();

    target.on('click', function() {
      target3.parent().removeClass('current');
      let atr = $(this).attr('data-comment');
      let item = $(atr);
      item.addClass('current');

      let ev = new Promise((resolve,reject) => {
        let atr = item.find('.download__item-anchor').attr('data-img');
        let img = $(atr);
        target2.removeClass('current');
        setTimeout(function() {
          target2.not('.current').hide();
          img.show();
          resolve(img);
        }, 300);
      });

      ev.then((img) => {
        img.addClass('current');
      });
    });
  }

  downloadEvent() {
    const target = $('.download__item-anchor');
    const target2 = $('.download__images__item');

    target2.not('.current').hide();

    target.on('click', function() {
      target.parent().removeClass('current');
      $(this).parent().addClass('current');

      let ev = new Promise((resolve,reject) => {
        let atr = $(this).attr('data-img');
        let img = $(atr);
        target2.removeClass('current');
        setTimeout(function() {
          target2.not('.current').hide();
          img.show();
          resolve(img);
        }, 300);
      });

      ev.then((img) => {
        img.addClass('current');
      });
    });
  }
}

fnc = new Fnc();

$(() => {
  fnc.ready();
});

$(window).on('load', function() {
  fnc.loaded();
});

$(window).resize(function() {
  fnc.resize();
});
